import React, { useState } from "react"
import axios from "axios"
import { Link } from "gatsby"

import GcLayout from "../components/GcLayout"
import SEO from "../components/seo"

export default () => {
  const [status, setStatus] = useState({
    submitted: false,
    submitting: false,
    info: { error: false, msg: null },
  })
  const [inputs, setInputs] = useState({
    email: "",
    message: "",
  })
  const handleServerResponse = (status, data) => {
    if (status == 200) {
      setStatus({
        submitted: true,
        submitting: false,
        info: { error: false, msg: "" },
      })
      setInputs({
        email: "",
        message: "",
      })
    } else {
      setStatus({
        info: { error: true, msg: "There was an error. Please try again." },
      })
    }
  }
  const handleOnChange = e => {
    e.persist()
    setInputs(prev => ({
      ...prev,
      [e.target.id]: e.target.value,
    }))
    setStatus({
      submitted: false,
      submitting: false,
      info: { error: false, msg: null },
    })
  }
  const handleOnSubmit = e => {
    e.preventDefault()
    setStatus(prevStatus => ({ ...prevStatus, submitting: true }))
    var bodyFormData = new FormData()
    bodyFormData.set(
      "query",
      '{msgtele(email:"' + inputs.email + '",msg:"' + inputs.message + '")}'
    )
    console.log(bodyFormData)
    axios({
      method: "POST",
      url: "/api/graphql",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function(response) {
        //handle success
        console.log(response)
        handleServerResponse(response.status, response.data)
      })
      .catch(function(response) {
        //handle error
        console.log(response)
        setStatus(prevStatus => ({
          ...prevStatus,
          submitting: false,
          submitted: true,
        }))
      })
  }
  return (
    <GcLayout>
      <SEO title="Contact" />
      <div class="container mx-auto mt-4">
        <h1 className="text-center text-gray-700">Contact Me</h1>
        <form
          class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
          onSubmit={handleOnSubmit}
        >
          <div class="mb-4">
            <label
              class="block text-gray-700 text-sm font-bold mb-2"
              for="email"
            >
              Email
            </label>
            <input
              class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              name="email"
              type="email"
              onChange={handleOnChange}
              required
              value={inputs.email}
            />
          </div>
          <div class="mb-6">
            <label
              class="block text-gray-700 text-sm font-bold mb-2"
              for="message"
            >
              Message
            </label>
            <textarea
              class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
              id="message"
              name="message"
              onChange={handleOnChange}
              required
              value={inputs.message}
            />
          </div>
          <div class="flex items-center justify-between">
            <button
              class="border-2 border-gray-500 hover:bg-gray-200 text-gray-700 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
              disabled={status.submitting}
            >
              {!status.submitting
                ? !status.submitted
                  ? "Submit"
                  : "Submitted"
                : "Submitting..."}
            </button>
            {status.info.error && (
              <div className="error">Error: {status.info.msg}</div>
            )}
            {!status.info.error && status.info.msg && <p>{status.info.msg}</p>}
          </div>
        </form>
        <p>
          To find out more about this wonderful contact form by visiting my blog page{" "}
          <Link className="text-blue-700" to="/blog/api/contact/me">here.</Link>
        </p>
      </div>
    </GcLayout>
  )
}
